@font-face {
  font-family: Cabin;
  src: url(font/Cabin_Condensed_Regular.ttf);
}

html, body {
  font-family: Cabin;
  height: 100%;
  margin: 0;
  overflow-x: hidden;

}

a {
  color: white;
  text-decoration: none;
}

.App {
  text-align: center;
}

.title {
  margin: 0;
  background-color: #004a7c;
  color: white;
  width: 100%;
  padding: 15px 0;
}

.nav {
  margin: 0;
  background-color: #004a7c;
  color: white;
  width: 100%;
  padding: 15px 20px 15px 0;
  text-align: right;
  opacity: 0.9;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Component: Covers */
.pointerCursor {
  cursor: pointer;
}

.guessWhat, .yf, .unknown {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  min-height: 200px;
}

.guessWhat {
  background-color: white;
}

.yf {
  background-color: orange;
}

.unknown {
  background-color: black;
  color: white;
}

.mediumZIndex {
  z-index: 2;
}

.lowZIndex {
  z-index: 1;
}

.mediumDelay {
  -webkit-animation: slideL2R 0.25s forwards;
  -webkit-animation-delay: 0.25s;
  animation: slideL2R 0.25s forwards;
  animation-delay: 0.25s;
}

.highDelay {
  -webkit-animation: slideL2R 0.5s forwards;
  -webkit-animation-delay: 0.5s;
  animation: slideL2R 0.5s forwards;
  animation-delay: 0.5s;
}

.slideL2R {
  position: relative;
  left: -100%;
  width: 100%;
  /* height: 100%; */
}

@-webkit-keyframes slideL2R {
  100% {
    left: 0;
  }
}

@keyframes slideL2R {
  100% {
    left: 0;
  }
}

.slideR2L33 {
  position: relative;
  left: 33.3%;
  width: 100%;
}

@-webkit-keyframes slideR2L33 {
  100% {
    left: 0;
  }
}

@keyframes slideR2L33 {
  100% {
    left: 0;
  }
}

.slideL2R33 {
  position: relative;
  left: -33.3%;
  width: 100%;
}

@-webkit-keyframes slideL2R33 {
  100% {
    left: 0;
  }
}

@keyframes slideL2R33 {
  100% {
    left: 0;
  }
}

.slideR2L {
  position: relative;
  left: 100%;
  width: 100%;
  /* height: 100%; */
}

@-webkit-keyframes slideR2L {
  100% {
    left: 0;
  }
}

@keyframes slideR2L {
  100% {
    left: 0;
  }
}

@media only screen and (max-width: 959px) {
  .guessWhat, .unknown, .yf {
    height: 33.4vh;
  }

  .slideR2L33 {
    position: relative;
    left: 100%;
    width: 100%;
  }

  .slideL2R33 {
    position: relative;
    left: -100%;
    width: 100%;
  }

  @-webkit-keyframes slideR2L33 {
    100% {
      left: 0;
    }
  }
  
  @keyframes slideR2L33 {
    100% {
      left: 0;
    }
  }

  @-webkit-keyframes slideL2R33 {
    100% {
      left: 0;
    }
  }
  
  @keyframes slideL2R33 {
    100% {
      left: 0;
    }
  }
}

/* About Page */
.aboutPage {
  text-align: center;
  justify-content: center;
}

.paragraph {
  text-align: left;
  padding: 12px 20px;
  border-bottom: 1px solid black;
  font-size: 18px;
}

.aboutWeb, .aboutMe, .contactInfo {
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  width: 100%;
}

.aboutWeb h1, .aboutMe h1, .contactInfo h1, .aboutWeb h5, .aboutMe h5, .contactInfo h5 {
  margin: 0;
}

.aboutWeb h1, .aboutMe h1, .contactInfo h1 {
  font-size: 70px;
  margin-top: 20px;

}

.aboutWeb h5, .aboutMe h5, .contactInfo h5 {
  line-height: 0.3;
  margin-bottom: 50px;
}

.aboutWeb h5 {
  font-size: 38px;
}

.aboutMe h5 {
  font-size: 35px;
}

.contactInfo h5 {
  font-size: 47px;
}

.MuiTypography, .MuiTypography-root, .MuiPaper, .MuiDialog-root, .MuiDialog-container, .MuiDialog-paper {
  font-family: Cabin !important;
  border-radius: 0 !important;
}

.moonMistDes {
  cursor: pointer;
  text-decoration: underline;
}

.webIcons {
  display: block;
  margin: auto;
  text-align: center;
}

.webIcons svg {
  cursor: pointer;
  margin: 0 5px;
}

.footer {
  background-color: orange;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
}

.footer h3 {
  text-align: right;
  margin: 20px;
  vertical-align: bottom;
  margin-top: 40px;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 5s, opacity 5s linear;
}

.emailSpan {
  text-align: center;
  text-decoration: underline;
  font-style: italic;
}